<template>
  <div class="d-flex justify-content-between align-items-center mb-3">
    <div>
      <h1 class="h4 mb-0">Downloads</h1>
    </div>
  </div>
  <div class="page-section page-section-last">
    <div class="row">
    <a href="../../resources/pdfs/code-template.pdf" target="_blank" class="quick-link col-md-6 mb-4">

      <div class="icon-column">
        <div class="icon">
          <i class="bi bi-printer"></i>
        </div>
      </div>
      <div class="text">
        <div><strong>Order Code Template</strong></div>
        <div class="text-description">Download our printable order code template </div>
      </div>
      <div class="arrow"><img src="@/assets/images/arrow.png" /></div>
      </a>
      <router-link :to="{ name: 'ReturnLabel'}" class="quick-link col-md-6 mb-4">
          <div class="icon-column">
            <div class="icon">
              <i class="bi bi-printer"></i>
            </div>
          </div>
          <div class="text">
            <div><strong>Freepost labels</strong></div>
            <div class="text-description">Create a freepost label to send your orders to us</div>
          </div>
          <div class="arrow"><img src="@/assets/images/arrow.png" /></div>
        </router-link>
      </div>
    </div>
</template>


<style scoped lang="scss">
.action
{
  padding-bottom: 20px;

  .action-link
  {
    display: flex;
    align-items: center;
  }

  .action-icon
  {
    aspect-ratio: 1/1;
    line-height: 0;
    font-size: 6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    width: 70px;
  }
}

.quick-link
{
  text-decoration: none;
  display: flex;

  .icon-column
  {
    display: flex;
    flex-direction: column;
    margin-right: $spacer;
  }

  .icon
  {
    background: $swift-lighter-grey;
    border-radius: 10px;
    aspect-ratio: 1/1;
    font-size: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
  }

  .text
  {
    flex-grow: 1;
    margin-right: $spacer;
  }

  .text-description
  {
    line-height: 1.3em;
  }

  .arrow
  {
    img
    {
      width: 30px;
      margin-top: 10px;
    }
  }

  &:hover
  {
    .icon
    {
      background: $swift-light-grey;
      transition: 0.5s;
    }
  }
}
</style>